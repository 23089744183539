export const items = [
  {
    title: 'drawer.items.home',
    path: '/',
    idx: 0,
    compactModeRoute: true,
  },
  {
    title: 'drawer.items.myChallenges',
    path: '/my-challenges',
    idx: 0,
    compactModeRoute: false,
    isPrivate: true,
  },
  {
    title: 'drawer.items.wallet',
    path: '/profile',
    idx: 2,
    compactModeRoute: false,
    isPrivate: true,
  },
  {
    title: 'drawer.items.profile',
    path: '/profile',
    idx: 3,
    compactModeRoute: false,
    isPrivate: true,
  },
  {
    title: 'drawer.items.faq',
    path: '/faq',
    idx: 2,
    compactModeRoute: true,
  },
  {
    title: 'drawer.items.about',
    path: '/about',
    idx: 3,
    compactModeRoute: true,
  },
];
