import React from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { items } from './items';

import { useSurviveAuth } from '../../SurviveAuth';

import CurrentSchedule from '../CurrentSchedule/CurrentSchedule';
import { app, isBlankPageRender } from '../../../config/app';

import {
  DrawerDeskWrapper,
  Header,
  Title,
  List,
  Item,
  ItemText,
  Version,
  LinkItem,
} from './style';
import { useReactiveVar } from '@apollo/client';
import { challengeTabsState } from '../../Apollo/vars';

export const DrawerDesk = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const { isAuthenticated } = useSurviveAuth();
  const hidden = isBlankPageRender(location.pathname);

  const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

  const tabState = useReactiveVar(challengeTabsState);

  return (
    <DrawerDeskWrapper hidden={hidden}>
      <CurrentSchedule size={10} width={12} />

      <Header>
        <Title>{t('drawer.title')}</Title>
      </Header>

      <List>
        {items.map(({ title, path, compactModeRoute, isPrivate }) => {
          if (compactMode && !compactModeRoute) return;

          if (isPrivate && !isAuthenticated()) return;

          return (
            <LinkItem
              to={path}
              key={title}
              onClick={() => {
                if (path === '/') {
                  delete tabState.appChallengesV2;
                  challengeTabsState({ ...tabState });
                } else if (path === '/my-challenges') {
                  delete tabState.appMyChallengesV2;
                  challengeTabsState({ ...tabState });
                }
              }}
            >
              <Item active={location.pathname === path && true}>
                <ItemText active={location.pathname === path && true}>
                  {t(title)}
                </ItemText>
              </Item>
            </LinkItem>
          );
        })}
      </List>

      <Version>
        {t('version.version')}: {app.version}
      </Version>
    </DrawerDeskWrapper>
  );
};

export default DrawerDesk;
