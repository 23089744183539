import React from 'react';

import keycloak, { initConfig } from '../../config/keycloack';

import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Slide } from 'react-toastify';
import { Errors } from '../Shared/Errors';
import { Toast } from './style';

import Globalstyle from '../Theme/GlobalStyle';
import Routes from '../Routes/Routes';
import Theme from '../Theme/Theme';
import Apollo from '../Apollo/Apollo';
import I18n from '../I18n/I18n';
import Layout from '../Shared/Layout/Layout';
import ApplicationLoading from './ApplicationLoading';
import NetworkStatus from '../../components/Shared/NetworkStatus/NetworkStatus';

import '../../config/firebase';

const App = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initConfig}>
      <Globalstyle />

      <ApplicationLoading>
        <Apollo>
          <BrowserRouter>
            <Errors />
            <I18n>
              <Theme>
                <NetworkStatus />

                <Toast
                  position="top-right"
                  autoClose={6000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  transition={Slide}
                />

                <Layout>
                  <Routes />
                </Layout>
              </Theme>
            </I18n>
          </BrowserRouter>
        </Apollo>
      </ApplicationLoading>
    </ReactKeycloakProvider>
  );
};

export default App;
